import './index.css';
import MKYHCK from './icons/MKY.mp4';

const Cheat = () => {
  return (
    <div className='flex h-screen justify-center items-center'>
        <div className='m-auto h-full'>
            <video autoPlay loop className='h-full'>
              <source src={MKYHCK} type='video/mp4'/>
            </video>
        </div>
    </div>
  );
}

export default Cheat;