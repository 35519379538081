import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';

import Home from './Home';
import Boosting from './Boosting';
import Cheat from './Cheat';


const App = () => {
  return (
    <Router>
      <div className='font-mono bg-black text-primary'>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/boosting' exact component={Boosting} />
          <Route path='/cheat' exact component={Cheat} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;