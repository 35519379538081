import './index.css';
import ParticleBackground from './ParticleBackground';
import { Link } from 'react-router-dom';

import { ReactComponent as Csgo } from './icons/csgo.svg';
import { ReactComponent as Lol } from './icons/lol.svg';

const Home = () => {
  return (
    <>
        <div className='
        -z-1 w-full h-full absolute
        '><ParticleBackground /></div>
        <div className='items-center'>
            <header className='text-center'>
            <h1 className='p-2 text-4vw'>MONKEYHACKS</h1>
            <p>Bestestest Cheat provider</p>
            </header>
            <main className='flex h-screen justify-around items-center'>
            <div className=''>
                <Link to='/cheat'><Csgo className='
                transform scale-50
                filter-svggreen
                '/></Link>
            </div>
            <div className=''>
                <Link to='/boosting'><Lol className='
                transform scale-50
                filter-svggreen
                '/></Link>
            </div>
            </main>
        </div>
    </>
  );
}

export default Home;