import './index.css';
import PSZ from './icons/PSZ.jpg'

const Boosting = () => {
  return (
    <div className='flex h-screen justify-center items-center'>
        <div className='m-auto'>
            <a target='_blank' rel='noreferrer' href='https://www.youtube.com/watch?v=TiJaDKM6XNg'>
                <h1 className='text-center text-4vw'>Suck it riot</h1>
                <img src={PSZ} alt="Logo" className='p-10 transform scale-125'/>
            </a>
        </div>
    </div>
  );
}

export default Boosting;